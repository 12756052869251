export const isEmailValid = (email: string) => {
  const validEmailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return validEmailPattern.test(email)
}

export const isAlphaNumeric = (str: string) => {
  const validAlphaNumericPattern = /^[a-z0-9 ]+$/i

  return validAlphaNumericPattern.test(str)
}

export const isContainsChineseCharacters = (
  str: string,
  allowEmpty: boolean = true,
) => {
  if (!str) {
    return allowEmpty
  }
  const chineseRegex = /[\u4E00-\u9FA5]/
  return chineseRegex.test(str)
}

export const isDateValid = (dateString: string, allowEmpty: boolean = true) => {
  //  MM/DD/YYYY
  const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/
  if (!dateString) {
    return allowEmpty
  }
  if (!regex.test(dateString)) {
    return false
  }

  const [month, day, year] = dateString.split('/')
  const date = new Date(`${year}-${month}-${day}`)
  return !isNaN(new Date(date).getTime())
}

export const isBirthDateValid = (dateString: string) => {
  const validRes = isDateValid(dateString)
  if (!validRes) {
    return false
  }
  const [month, day, year] = dateString.split('/').map(Number)
  const date = new Date(year, month - 1, day)
  const now = new Date()
  if (date > now) {
    return false
  }
  const minDate = new Date('1900-01-01')
  return date >= minDate
}
